<template>
  <div class="content">
    <loader v-if="!pageLoaded"></loader>
    <div v-else style="margin-bottom: 100px; margin-left: 57px; margin-top:30px;">

      <router-link to="/panel/istekler" class="cuper-black-button" style="width: 113px; height: 46px;">
        Geri Dön
      </router-link>

      <div v-if="warning.displayWarning && warning.disapprovals.length" class="request-warning">
        <div class="request-warning-inside">
          <div>
            <img src="https://gcdn.bionluk.com/site/cicons/ic-warning.svg"  onload="SVGInject(this)" class="warning-icon">
          </div>
          <div style="margin-left: 15px;">
            <p class="warning-head">Onaylanmadı</p>
            <p class="warning-sub-head">Aşağda belirtilen hataları gidererek, onay aşamasına gönderebilirsin.</p>

            <p class="disapproval-row" v-for="disapproval in warning.disapprovals">
              <span class="warning-description-html">{{disapproval.title}}</span>
              <span class="warning-description-html" v-if="disapproval.type === 'custom'" v-html="disapproval.description" style="font-weight: 300"></span>
              <router-link class="link" v-if="disapproval.support_link_url" :to="disapproval.support_link_url">[ayrıntılı bilgi]</router-link>
            </p>

          </div>
        </div>
      </div>


      <div class="tabs">
        <div @click="tab = 'detail'" :class="tab === 'detail' ? 'tab-bar-active' : 'tab-bar'" style="border-top-left-radius: 5px;">
          <p class="text">Alıcı İsteğinin Detayları</p>
        </div>
        <div @click="tab = 'offers'" :class="tab === 'offers' ? 'tab-bar-active' : 'tab-bar'" style="border-top-right-radius: 5px;">
          <p class="text">Gelen Teklifler <span v-if="offer_count">[{{offer_count}}]</span></p>
        </div>
      </div>

      <div v-if="!offer_count"  class="finance-info for-seller">
        <div  style="margin-left: 20px">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-seller-icon"/>
        </div>

        <div style="margin-left: 15px">
          <div class="title">Henüz bir teklif yok</div>
          <div class="description">
            <span v-if="is_plus">En kısa zamanda <span style="font-weight: bold">Bionluk Plus müşteri temsilcimiz</span> seninle e-posta yoluyla iletişime geçecek.</span>
            <span v-else>Bu alanda sana gelen teklifleri inceleyebilirsin. Yeni bir teklif aldığında seni <span style="font-weight: bold">e-posta yoluyla</span> bilgilendireceğiz.</span>
          </div>
        </div>


      </div>

      <div v-if="tab === 'detail'">
        <div class="request-box">
          <div class="head">
            <div style="display: flex; margin-left: 20px; align-items: center">
              <img :src="user.avatar_url" class="avatar">
              <div style="margin-left: 15px; display: flex; flex-direction: column; height: 46px; justify-items: center;">
                <p class="username">{{user.username}}</p>
                <p class="usertitle">{{user.info.title}}</p>
              </div>

            </div>
            <div style="display: flex; align-items: center; margin-right: 30px;">
              <div :class="'head-label ' + helpTextClass">
                {{helpText}}
              </div>
              <div
                :data-balloon="status === 1 ? 'Yayından Kaldır' : 'Tekrar Yayına Al'"
                data-balloon-pos="up"
                v-if="status === 1 || status === 2" @click="iconAction('status')" class="icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-eye-close.svg"  onload="SVGInject(this)" class="head-eye-icon">
              </div>
              <div
                data-balloon="Sil"
                data-balloon-pos="up"
                @click="iconAction('delete',request_uuid)" class="icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg"  onload="SVGInject(this)" class="head-delete-icon">
              </div>
              <div
                data-balloon="Düzenle"
                data-balloon-pos="up"
                @click="iconAction('edit',request_uuid)" class="icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg"  onload="SVGInject(this)" class="head-edit-icon">
              </div>

            </div>
          </div>
          <div class="bodyx">
            <div class="breadcrumb" v-if="category.id && category_sub.id">
              <span>{{category.name}}</span>
              <img style="margin-left: 10px; margin-right: 10px;" src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg"/>
              <span>{{category_sub.name}}</span>
              <template v-if="service_types && service_types.length">
                <img style="margin-left: 10px; margin-right: 10px;" src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg"/>
                <span>{{service_types[0].name }}</span>
              </template>
              <template v-if="wordCount">
                <span class="extra-info-label">{{wordCount}} Kelime</span>
              </template>

            </div>

            <p class="body-title">{{title}}</p>
            <p class="body-text" v-html="description"></p>
            <div v-for="upload in uploads" style="  padding: 5px 40px 15px; margin-top: 5px; margin-bottom: 8px; display: flex; align-items: center">
              <img style="height: 27px; width: 21px; " :src="upload.icon_png">
              <a target="_blank" :href="upload.url" :download="upload.filename" style="color: #2d3640; padding-left: 8px;" >{{ upload.filename }}</a>
            </div>

            <div style="display: flex;" v-if="meta_options_with_names && meta_options_with_names.length" class="request-meta_options">
              <div style="margin-right: 30px;" v-for="meta_option in meta_options_with_names">
                <p class="meta-option-title">{{meta_option.title}}</p>
                <div>
                  <p class="meta-option-values" v-for="option in meta_option.options">{{option}}</p>
                </div>
              </div>
            </div>

          </div>
          <div class="foot">
            <div class="foot-date">
              {{ Number(created_at) | customDateFormat('dd MMM yyyy') }}
            </div>
            <div class="foot-right">

              <div class="item">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-special-offer.svg"  onload="SVGInject(this)" class="foot-icon">
                <p class="foot-title">Gelen Teklif: </p>
                <p class="foot-value">{{offer_count}}<span @click="tab='offers'" v-if="offer_count" class="foot-show">[göster]</span></p>
              </div>

            </div>

          </div>
        </div>


        <div style="margin-bottom: 0; margin-top:10px; display: flex">
          <div style="display: inline-block">
            <label class="info-label">Kategori</label>
            <p class="info-drop">
              {{category.name}}
            </p>
          </div>

          <div style="display: inline-block; margin-left: 50px">
            <label></label>
            <label class="info-label">Alt Kategori</label>
            <p class="info-drop">
              {{category_sub.name}}
            </p>
          </div>
        </div>


        <!-- service type eğer varsa !! sadece çeviri ve seslendirmeyse -->

        <div  v-if="category_sub.id && (category_sub.id === 61 || category_sub.id === 38)" style="margin-bottom: 0; margin-top:10px; display: flex">
          <div v-if="category_sub.id && service_kinds && service_kinds.length > 0" style="display: inline-block;">
            <label></label>
            <label class="info-label">{{service_kinds[0].title}}</label>
            <p class="info-drop">
              {{service_kinds[0].name}}
            </p>
          </div>

          <div v-if="service_types && service_types.length > 0 " style="display: inline-block; margin-left: 50px">
            <label class="info-label"> {{service_types[0].title}}</label>
            <p class="info-drop">
              {{service_types[0].name}}
            </p>
          </div>
          <div v-else-if="category_sub.id === 61" style="display: inline-block; margin-left: 50px">
            <label class="info-label">Seslendirmen</label>
            <p class="info-drop">
              Farketmez
            </p>
          </div>

        </div>



        <div style="margin-bottom: 0; margin-top:10px; display: flex">
          <div style="display: inline-block">
            <label class="info-label">Tahmini Teslim Süresi</label>
            <p class="info-drop">
              {{duration}}
            </p>
          </div>

          <div style="display: inline-block; margin-left: 50px">
            <label></label>
            <label class="info-label">Tahmini Bütçe</label>
            <p class="info-drop" style="font-size: 30px;font-weight: 600;">{{budget}} <span style="font-weight: normal"> ₺</span></p>
          </div>
        </div>

        <div style="margin-bottom: 0; margin-top:10px; display: flex">
          <div v-if="wordCount" style="display: inline-block">
            <label class="info-label">Toplam kelime sayısı</label>
            <p class="info-drop">
              {{wordCount}}
            </p>
          </div>

          <div :style="wordCount ? 'display: inline-block; margin-left: 50px' : 'display: inline-block;'">
            <label v-if="uploads && uploads.length > 0" class="info-label">Paylaştığın Dosyalar</label>
            <div v-for="upload in uploads" class="file-rect">
              <div style="display: flex;width:100%;justify-content: space-between; align-items: center ">
                <div style="display: flex; align-items: center;">
                  <div>
                    <img :src="upload.icon_png">
                  </div>
                  <div>
                    <p class="title"> <a target="_blank" :href="upload.url" :download="upload.filename" style="color: #2d3640;" >{{ upload.filename }}</a></p>
                    <p class="desc">{{ upload.created_at | customDateFormat('dd MMMM, yyyy - HH:mm') }}</p>
                  </div>
                </div>
                <a target="_blank" :href="upload.url" :download="upload.filename" class="download-icon-container">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-download.svg" onload="SVGInject(this)" class="download-icon">
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div v-if="tab === 'offers'">

        <div class="offer-box" v-for="(offer, index) in offers" :key="index">
          <div class="head">
            <div style="display: flex; margin-left: 30px; align-items: center">
              <router-link  :to="'/' + offer.seller.username">
                <img :src="offer.seller.avatar_url" class="avatar">
              </router-link>
              <div style="margin-left: 15px; display: flex; flex-direction: column; height: 46px; justify-items: center;">
                <p class="username">
                  <router-link class="username"  :to="'/' + offer.seller.username">
                    {{offer.seller.username}}
                  </router-link>
                </p>
                <p class="usertitle">{{offer.seller.title}}</p>
              </div>

            </div>
            <div class="head-right">
              <p class="money">{{offer.price}}<span class="tl">TL</span></p>
              <p class="gun">{{offer.duration}} gün <span class="teslim">içinde teslim</span></p>
            </div>

          </div>
          <div class="body">
            <p class="offer-text" v-html="offer.description"></p>

          </div>
          <div v-show="offer.expandible" @click="expand(offer, index)" class="read-more"><p>Devamını oku</p><p style="padding-top: 4px; padding-left: 4px;"><img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-down.svg" onload="SVGInject(this)" class="read-more-icon"></p></div>

          <div class="foot">
            <p v-if="!offer.order_id" @click="removeOffer(offer, index)" class="remove-offer">[Teklifi Kaldır 👎]</p>
            <button :disabled="buttonLoading" @click="sendMessage(offer)" class="gray-button">Mesaj Gönder</button>
            <button v-if="!offer.order_id" @click="buyNow(`offer`, offer.id)" :disabled="buttonLoading" class="cuper-red-button" style="width: 140px; height: 40px; margin-right: 30px;font-size: 14px; font-weight: 600;">Hemen Al</button>
            <button v-else @click="$router.push('/orders/'+offer.order_id)" :disabled="buttonLoading" class="cuper-red-button" style="width: 140px; height: 40px; margin-right: 30px;font-size: 14px; font-weight: 600;">Siparişe Git</button>
          </div>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
  export default {
    name: "src-pages-body-workstation-requests-offers-v3",
    data() {
      return {
        tab:'detail',
      	buttonLoading: false,
        uploads:[],
        service_types: [],
        service_kinds: [],
        meta_options_with_names:[],
        category:{},
        category_sub:{},
        wordCount: 0,
        skills: [],
        duration: null,
        budget: null,
        description: null,
        title: null,
        helpText: null,
        helpTextClass: null,
        status: null,
        quantity: null,
        offers: [],
        offer_count: 0,
        pageLoaded: false,
        created_at:0,
        warning: {
          displayWarning:false
        },
        request_uuid:null,
        request_id:null,
        is_plus:false,
      }
    },

    methods: {


      isOverflown(element) {
        if(element){
          return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        } else {
          return false;
        }
      },

      expand(offer, index){
        let elems = document.getElementsByClassName("body");
        elems[index].style.height = offer.expandible+'px';
        elems[index].style.overflow = 'visible';
        const offers = JSON.parse(JSON.stringify(this.offers));
        offers[index].expandible = false
        this.offers = offers;
      },

      iconAction(action){
        if(action === 'edit'){
          this.$router.push('/panel/istekler/' + this.request_uuid +'/duzenle')
        }
        if(action === 'status'){
          let new_status = this.status === 1 ? 2 : 1;
          this.api.user.changeRequestStatus(this.request_uuid, new_status, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.$toasted.global.infoToast({description: result.message});
                this.status = new_status;
                this.helpTextClass = new_status === 1 ? 'green' : 'red';
                this.helpText = new_status === 1 ? 'Yayında' : 'Yayında Değil';
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });

        }

        if(action === 'delete'){
          this.api.user.changeRequestStatus(this.request_uuid, -1, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.$toasted.global.infoToast({description: result.message});
                this.$router.push('/panel/istekler');
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }

      },

      refreshPage() {
        location.reload();
      },
      getRequestOffer(slug) {

        // TODO seemorediv yapılacak
        this.api.request.get(slug)
          .then(({data}) => {
            let result = data;

            if(result.success) {
              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

              this.pageLoaded = true;
              this.uploads = result.data.uploads;
              this.service_types = result.data.request_service_types;
              this.service_kinds = result.data.request_service_kinds;
              this.meta_options_with_names = result.data.request_meta_options_with_names;
              this.wordCount = result.data.wordCount;
              this.uploads = result.data.uploads;
              this.skills = result.data.skills;
              this.duration = result.data.duration;
              this.budget = result.data.budget;
              this.description = result.data.description;
              this.title = result.data.title;
              this.helpText = result.data.helpText;
              this.helpTextClass = result.data.helpTextClass;
              this.status = result.data.status;
              this.created_at = result.data.created_at;
              this.request_uuid = result.data.request_uuid;
              this.request_id = result.data.request_id;
              this.category = result.data.category;
              this.category_sub = result.data.category_sub;

              this.offers = result.data.offer_info.offers;
              this.offer_count = result.data.offer_info.offer_count;
              this.is_plus = result.data.is_plus;
              this.warning = result.data.warning;
            } else {
                this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      buyNow(gigType, offerId) {

        this.buttonLoading = true;
        this.api.orders.giveOrder(gigType, offerId)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: result}});
              this.Helper.trackEvents.pageView(this.Helper.checkoutPageProps(result.data), "checkout", "modal_" + this.$store.state.modalComponentMap.checkout);
              this.trackConversionForCheckoutedOrders(result.data.total, "TRY");

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.buttonLoading = false;
          })
          .catch(err => {
            this.buttonLoading = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      sendMessage(offer) {

        this.$store.state.clickedFrom = 'button';
	      if (!this.$store.state.user.uuid) {
		      this.$router.push("/login");
	      } else {

		      if(this.buttonLoading === false){
			      this.buttonLoading = true;
			      this.api.workstation.chatOpen(offer.seller.username, 0,1)
				      .then(({data}) => {
					      let result = data;
					      if (result.success) {
						      let conversationSendData = {
							      r_username: offer.seller.username,
							      r_avatar_url: offer.seller.avatar_url,
							      r_id: offer.seller.seller_id,
							      r_uuid: offer.seller.seller_uuid,
							      fromWhere: 'Buyer Requests',
                    whereFrom: 'Buyer Requests',
							      c_uuid: result.data.c_uuid,
                    request_id: this.request_id,
                    request_uuid: this.request_uuid
						      };
						      this.EventBus.$emit('externalOpenChat', conversationSendData);
					      } else {
						      this.$toasted.global.errorToast({description: result.message});
					      }
					      this.buttonLoading = false;
				      })
				      .catch(err => {
					      this.buttonLoading = false;
					      this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
				      });
		      }
	      }

      },

      removeOffer(offer, index){

        this.offers.splice(index, 1);
        this.api.seller.cancelOffer(offer.id)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              offer.cancelled_at = 1;
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });


      },

      actionButton(endpoint, parameters) {
        this.api.general.requestToEndPoint(endpoint, parameters, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.$router.push(result.data.redirect_url);
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },

    watch:{
      tab:function (newVal, oldVal) {
        window.scrollTo(0,0);
        if(newVal === 'offers'){
          if(this.offers.length){
            this.$nextTick(function () {
              // DOM updated

              const offers = JSON.parse(JSON.stringify(this.offers));

              let elems = document.getElementsByClassName("body");

              for (let i = 0; i < elems.length; i++) {
                if(this.isOverflown(elems[i])){
                  offers[i]['expandible'] = elems[i].scrollHeight;
                } else {
                  offers[i]['expandible'] = false;
                }
                this.offers = offers;
              }
            });
          }
        }
      },
      'route.query.tab': function (newVal, oldVal) {
        if (newVal === 'offers') {
          this.tab = 'offers';
        } else if (newVal === 'detail') {
          this.tab = 'detail';
        }
      },
      offers: function (newVal, oldVal) {
        if(newVal.length && newVal.length !== oldVal.length){
          this.$nextTick(function () {
            // DOM updated

            const offers = JSON.parse(JSON.stringify(this.offers));

            let elems = document.getElementsByClassName("body");

            for (let i = 0; i < elems.length; i++) {
              if(this.isOverflown(elems[i])){
                offers[i]['expandible'] = elems[i].scrollHeight;
              } else {
                offers[i]['expandible'] = false;
              }
              this.offers = offers;
            }
          });
        }
      }
    },

    created() {
      if (this.route.query.tab === 'offers') {
        this.tab = 'offers';
      } else {
        this.tab = 'detail';
      }



      this.Helper.trackEvents.pageView();
      this.getRequestOffer(this.$store.state.routerParams[0]);
    }
  }

</script>

<style scoped lang="scss">


.request-meta_options{
  padding-top: 15px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
  border-top: 1px solid #dadbdd;

  .meta-option-title{
    color: #95979d;
    margin-bottom: 5px;
  }

  .meta-option-values{
    color: #62646a;
    margin-bottom: 3px;

  }
}

.breadcrumb {
  font-size: 13px;
  text-align: left;
  color: #5e6b79;
  margin-top: 10px;
  margin-left: 30px;
}



.finance-info{
  width: 843px;
  height: 100px;
  border-radius: 10px;
  border: solid 1px rgba(242, 153, 51, 0.3);
  background-color: rgba(242, 153, 51, 0.2);
  margin-top: 30px;
  display: flex;
  align-items: center;
  position: relative;
  &.for-seller {
    border: solid 1px rgba(90, 38, 164, 0.3);
    background-color: rgba(90, 38, 164, 0.2);
  }
  .for-seller-icon /deep/{
    width: 36px;
    height: 36px;
    path{
      fill: #5a26a4;
    }
  }
  .for-buyer-icon /deep/{
    width: 36px;
    height: 36px;
    path{
      fill: #f29933;
    }
  }
  .delete-icon /deep/{
    width: 16px;
    height: 16px;
    path{
      fill: #2d3640;
    }
  }
  .title{
    font-size: 16px;
    font-weight: 500;
    color: #343d4a;
    padding-bottom: 4px;
  }
  .description{
    width: 668px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    color: #4f596c;
  }
}

  .file-rect{

    width: 368px;
    height: 57px;
    border-radius: 5px;
    box-shadow: 0 0 0 0 #2d36400c;
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    img{
      width: 21px;
      height: 27px;
      margin-left: 20px;
      margin-right: 10px;
    }
    .title{
      font-size: 13px;
      font-weight: 500;
      color: #2d3640;
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      height: 18px;
    }
    .desc{
      font-size: 9px;
      font-weight: normal;
      color: #969fac;
    }

    .download-icon-container{
      padding-right: 20px;
      cursor: pointer;
      &:hover{
        opacity: 0.7;
      }
    }
    .download-icon /deep/ {

      width: 18px;
      height: 16px;
      path {
        fill: #8b95a1;
      }
    }

  }
  .info-label{
    font-size: 26px;
    font-weight: 500;
    color: #2d3640;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .info-drop{
    width: 368px;
    height: 79px;
    border-radius: 5px;
    border: solid 1px #bfc8d2;

    font-size: 18px;

    display: flex;
    align-items: center;
    padding-left: 25px;
    color: #2d3640;
  }


  .read-more{
    margin-top: 10px;
    margin-left: 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    color: #f75466;
    display: flex;
    align-items: center;
    height: 20px;
    padding-bottom: 10px;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }

    .read-more-icon /deep/ {

      width: 14px;
      height: 14px;
      path {
        fill: #fd4056;
      }
    }

  }

  .tabs{

    margin-top: 30px;
    cursor: pointer;
    display: flex; height: 70px; width: 843px;

    .tab-bar{
      width: 422px;
      height: 67px;
      border-bottom: 3px solid #e6e9ee;
      background-color: #e6e9ee;
      display: flex;
      align-items: center;
      justify-content: center;
      .text{
        font-size: 18px;
        font-weight: 500;
        color: #8b95a1;

      }

      &:hover{
        .text{
          font-size: 18px;
          font-weight: 600;
          color: #3c4654;

        }
      }
    }


    .tab-bar-active{
      width: 422px;
      height: 67px;
      background-color: #ffffff;
      border-bottom: 3px solid #2d3640;
      display: flex;
      align-items: center;
      justify-content: center;

      .text{
        font-size: 18px;
        font-weight: 600;
        color: #3c4654;

      }
    }

  }


  .request-warning{



    width: 843px;
    margin-top: 35px;
    border-radius: 10px;
    border: solid 1px rgba(242, 153, 51, 0.3);
    background-color: rgba(242, 153, 51, 0.2);

    .request-warning-inside{
      display: flex;
      padding: 20px 25px;
    }

    .warning-head{
      font-size: 16px;
      font-weight: normal;

      color: #2d3640;
    }
    .warning-sub-head{
      margin-bottom: 20px;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 300;

      line-height: 1.14;

      color: #2d3640;
    }


    .warning-icon /deep/ {
      width: 35px;
      height: 32px;
      path {
        fill: #f29933;
      }
    }


    .disapproval-row{
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: #2d3640;
      .link{
        font-weight: bold;
        color: #fd4056;
      }
    }

  }


  .offer-box{
    margin-top: 50px;

    width: 843px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    .head{
      width: 843px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      background-image: linear-gradient(85deg, #71d9cf 6%, #54aeb8 97%);

      .avatar{
        height: 44px;
        width: 44px;
        border: 1px solid #fff;
        border-radius: 50%;
      }

      .username{
        font-size: 18px;
        font-weight: normal;
        color: #ffffff;
      }

      .usertitle{
        margin-top: 5px;
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
      }

    }


    .head-right{

      text-align: right;
      margin-right: 30px;

      .money{
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -1.73px;
        color: #ffffff;
      }

      .tl{
        padding-left: 5px;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
      }

      .gun{
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
      }
      .teslim{

        font-weight: 300;
      }
    }
    .body{
      height: 130px;
      overflow-y:hidden;
      font-size: 16px;
      line-height: 1.63;
      color: #5e6b79;

      .offer-text{
        padding:25px 30px;
        font-size: 16px;
        line-height: 1.63;
        color: #5e6b79;
      }
    }

    .foot{
      width: 843px;
      height: 70px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0 -1px 0 0 #eaedf2;
      background-color: #f9fafb;
      display: flex;
      align-items: center;
      justify-content: flex-end;

    }

    .remove-offer{
      cursor: pointer;
      margin-right: 30px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: -0.23px;
      text-align: center;
      color: #fd4056;
      &:hover{
        text-decoration: underline;
      }
    }
    .gray-button{
      margin-right: 30px;
      width: 120px;
      height: 40px;
      border-radius: 2px;
      border: solid 1px rgba(45, 54, 64, 0.2);
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: -0.26px;
      text-align: center;
      color: #2d3640;
      background: white;
      &:hover{
        color: #fff;
      }
      &:disabled{
        background: white!important;
        color: #2d3640!important;
      }
    }
  }

  .request-box{
    margin-top: 50px;
    width: 843px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.15);
    background-color: #ffffff;

    .extra-info-label
    {

      height: 21px;
      padding: 2px 10px 3px;
      border-radius: 10px;
      background-color: #26ca9a;
      font-size: 12px;
      letter-spacing: -0.22px;
      font-weight: normal;

      text-align: center;
      margin-left: 10px;
      color: #ffffff;
    }

    .head{
      width: 843px;
      height: 80px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #8b95a1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .head-active{
      width: 843px;
      height: 80px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #2d3640;
    }

    .avatar{
      height: 44px;
      width: 44px;
      border: 1px solid #fff;
      border-radius: 50%;
    }

    .username{
      font-size: 18px;
      font-weight: normal;
      color: #ffffff;
    }

    .usertitle{
      margin-top: 5px;
      font-size: 14px;
      font-weight: normal;
      color: #ffffff;
    }

    .head-label{
      padding-left: 20px;
      padding-right: 20px;
      height: 28px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      color: #ffffff;
      margin-left: 20px;
      padding-bottom: 2px;
      a{
        color: #ffffff;
      }
    }

    .green{
      background-color: #6dce6a;
    }




    .red{
      background-color: #fd4056;
    }

    .yellow{
      background-color: #f29933;
    }


    .icon-container{
      width: 30px;
      height: 30px;
      border-radius: 5px;
      background-color: #ffffff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;


      .head-eye-icon /deep/ {
        width: 14px;
        height: 14px;
        path {
          fill: #2d3640;
        }
      }

      .head-delete-icon /deep/ {
        width: 11px;
        height: 14px;
        path {
          fill: #2d3640;
        }
      }

      .head-edit-icon /deep/ {
        width: 14px;
        height: 14px;
        path {
          fill: #2d3640;
        }
      }

      &:hover{
        .head-eye-icon /deep/ {
          width: 14px;
          height: 14px;
          path {
            fill: #00a575;
          }
        }

        .head-delete-icon /deep/ {
          width: 11px;
          height: 14px;
          path {
            fill: #00a575;
          }
        }

        .head-edit-icon /deep/ {
          width: 14px;
          height: 14px;
          path {
            fill: #00a575;
          }
        }
      }


    }


    .bodyx{
      min-height: 192px;


      .body-title{
        padding: 25px 30px;
        font-size: 18px;
        font-weight: bold;
        color: #2d3640;
      }
      .body-text{
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 10px;
        font-size: 16px;
        line-height: 1.63;
        color: #5e6b79;
        word-break: break-word;
        white-space: pre-wrap;
      }
    }

    .foot{
      width: 843px;
      height: 70px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0 -1px 0 0 #eaedf2;
      background-color: #f9fafb;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .foot-date{
        margin-left: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #f4f5f7;
        padding-right: 20px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #8b95a1;
      }

      .foot-right{
        display: flex;
        margin-right: 30px;
        .item{
          margin-left: 30px;
          display: flex;
          align-items: center;

          .foot-icon /deep/ {

            width: 24px;
            height: 24px;
            path {
              fill: #8b95a1;
            }
          }

          .foot-title{
            margin-left: 10px;
            font-size: 16px;
            font-weight: normal;
            color: #2d3640;
          }

          .foot-value{
            margin-left: 5px;
            font-size: 16px;
            font-weight: 600;
            color: #2d3640;
          }

          .foot-show{
            cursor: pointer;
            margin-left: 5px;
            font-size: 16px;
            font-weight: 600;
            color: #00a575;
            &:hover{
              text-decoration: underline;
            }
          }

        }
      }
    }
  }


  .chatrow {
    margin-left: 30px;
    margin-right: 30px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-top: solid 1px #eaedf2;
    width: 820px;
    padding: 20px 0;
  }

  .numberCircle {

    border-radius: 50%;
    width: 36px;
    font-size: 28px;
    border: 2px solid #2d3640;

  }

  .numberCircle span {

    text-align: center;
    line-height: 30px;
    display: block;
    color: #5e6b79;
    height: 36px;
  }

  .todoDescription {
    width: 350px;
  }

  .todoWho {
    color: #2d3640;
    text-decoration: underline;
  }

  .todoDeadline {
    flex: 130;
    padding-left: 12px;
    font-size: 14px;
  }

  .todoDone {
    text-decoration: line-through !important;
  }

  .todoMenuItem {
    cursor: pointer;
    color: #8b95a1;
    font-weight: 500;
  }

  .todoMenuItem:hover {
    color: #2d3640;
  }

  .todoMenu {
    margin-left: 633px;
    z-index: 2;
    margin-top: 12px;
    position: absolute;
    width: 150px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
    height: auto
  }

  .closeTodoMenu {
    cursor: pointer;
    position: absolute;
    margin-left: 155px;
    margin-top: -10px;
    height: 10px;
  }

  .openTodoMenu {
    cursor: pointer;
    height: 6px;
  }

  .super-drop-tek-cizgi {
    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    background-position-x: 115px;
    -moz-appearance: none;
    min-width: 140px;
    appearance: none;
    border-radius: 2px;
    font-family: 'sofia-pro';

    position: absolute;;

    background-color: #fefdfd;
    color: #939c9c;

    border: none;
    border-bottom: solid 1px #bfc8d2;

    font-size: 14px;
    padding: 4px 5px 6px;
  }

  .super-drop-tek-cizgi:hover {

    border: none;
    border-bottom: solid 1px #2d3640;
    color: #2d3640;
    cursor: pointer;
  }

  .menu-list {
    line-height: 1.25;
  }

  ul {
    list-style: none;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  li:hover {
    display: list-item;
    text-align: -webkit-match-parent;
    color: #2d3640;
  }

  .menu-list li {
    cursor: pointer;
    border-radius: 2px;
    color: #4a4a4a;
    display: block;
    padding: .5em .75em;
  }

  .menu-list li:hover {
    border-radius: 2px;
    color: #2d3640;
    display: block;
    padding: .5em .75em;
    text-decoration: none;
  }


  /*warning*/

  .application-warning {
    width: 100%;
    height: 70px;
    margin-top: 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    margin-bottom: 30px;
    .application-warning-inner {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  .warning-header {
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
  }

  .warning-text {
    font-size: 16px;
    margin-left: 18px;
  }

  .warning-action-button {
    width: 134px;
    height: 44px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c6c8ca;
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
    margin-right: 15px;
  }

  .warning-action-button:hover {
    background-color: #fbfbfb !important;
  }

  .separator {
    height: 30px;
    width: 0;
    border-left: solid 1px #e6eaea;
    margin-left: 19px;
  }



  .removeOffer:hover{
    text-decoration: underline;

  }


  .request_type_box{
    display:inline-block; color: #777;

    padding: 4px 8px;

    font-size: 12px;
    font-weight: 400;
    background-color: #fff;

    line-height: 14px;

    border: solid 1px #ddd;
    border-radius: 3px;
    margin-right: 6px;
    margin-top: 4px;
  }

</style>
